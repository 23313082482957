/**
 * Swaps item from array from one index to another
 * Returns false otherwise
 * @param arr array where the swap should happen
 * @param fromIndex the index where the swap should happen from
 * @param toIndex the index where the swap should happen to
 */
export function swapIndex(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}


/**
 * Returns true if the string is null, undefined or empty
 * Returns false otherwise
 * @param str String to be tested against
 */
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';

export function isNullOrEmpty(str: string): boolean {
  return str === null || str === undefined || str.length === 0;
}

/**
 * Returns true if the value parsed in is null or undefined.
 * Returns false otherwise
 * @param str Object to tested against
 */
export function isNullOrUndefined(str: any): boolean {
  return str === null || str === undefined;
}


/**
 * Takes the value parsed in and copies to the system clipboard
 * @param val
 */
export function copyToClipBoard(val: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (navigator.clipboard === undefined) {
      reject('Clipboard API is undefined. Browser is not compatible');
    }

    return navigator.clipboard.writeText(val).then(resolve).catch(reject);
  })
}

type Environment = 'Development' | 'Alpha' | 'RC' | 'Production';

/**
 * Get the current hosting environment.
 *
 * Compares the environment.apiUrl and returns correct type
 */
export function getEnvironment(): Environment {
  const url = new URL(environment.apiUrl);
  let returnVal: Environment;

  switch (url.host) {
    case 'portal.api.alpha.cloudfactory.dk':
      returnVal = 'Alpha';
      break;
    case 'portal.api.rc.cloudfactory.dk':
      returnVal = 'RC';
      break;
    case 'portal.api.cloudfactory.dk':
      returnVal = 'Production';
      break;
    default:
      returnVal = 'Development';
      break;
  }
  return returnVal;
}

/**
 * Get the apiUrl based on the Environment parsed in
 * @param e
 */
export function getHomeUrl(e: Environment): string {
  let returnVal = '';
  switch (e) {
    case 'Production':
      returnVal = 'https://portal.cloudfactory.dk/';
      break;
    case 'RC':
      returnVal = 'https://portal.rc.cloudfactory.dk/';
      break;
    case 'Alpha':
      returnVal = 'https://portal.alpha.cloudfactory.dk/';
      break;
    case 'Development':
      returnVal = 'https://localhost:4200';
      break;
  }

  return returnVal;
}

/**
 * This function will take the obj parsed in and add the prefix in every property name of the object
 * @param obj
 * @param prefix
 */
export function addPrefixToObject<P extends string>(obj: any, prefix: P): object {
  let o = {}
  Object.keys(obj).forEach((key: string) => {
    o[prefix + key] = obj[key];
  });
  return o;
}
/**
 * Takes in a object and converts the object to parseable Http Params that can be used on a HttpRequest
 * It only maps the values that are defined, so undefined and null values will be left out.
 *
 * This method supports the following data types: string, number, boolean, Date (Native).
 * @param source Object to convert to Http params
 * @param target existing HttpParams to extend to
 */
export function buildQueryParamsByObject(source: any, target?: HttpParams): HttpParams {
  if (target == undefined) target = new HttpParams();
  Object.keys(source).forEach((key: string) => {
    const value: string | number | boolean | Date | object = source[key];
    if ((typeof value !== 'undefined') && (value !== null)) {
      if (value instanceof Array || value instanceof Set) {
        value.forEach(prop => {
          target = target.append(key, prop.toString());
        });
      }
      else if (typeof value === 'object') {
        const obj = addPrefixToObject(value, `${key}.`);
        target = buildQueryParamsByObject(obj, target);
      }
      else {
        const split: string[] = key.split('.');

        let resultKey: string;

        if (Number.isNaN(Number(split[0])) === true) {
          resultKey = key;
        } else {
          resultKey = split[1];
        }

        target = target.append(resultKey, value.toString());
      }
    }
  });
  return target;
}

/**
 * Yield back to main thread
 */
export function yieldToMain() {
  return new Promise(resolve => {
    setTimeout(resolve, 0);
  });
}

/**
 * Returns a deep copy of the object
 */
export function deepCopy(Object: any) {
  return JSON.parse(JSON.stringify(Object))
}

/**
 * Since angular will format the date to UTC in it's payload in a http request (resulting in the wrong date being sent),
 * we can use this function to offset the timezone so we send the right date in the request
 */
export function formatDateOffsetTimezone(inputDate: Date | string | null): Date {
  if (!inputDate || inputDate === null) return null;

  let date: Date = new Date(inputDate);

  const hours = (date.getTimezoneOffset() / 60) * -1;
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();

  date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds, milliseconds);

  return date;
}